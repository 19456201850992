import React from "react"
import Seo from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"
// import { StoreContext } from "../contexts/Store"
import ProductsList from "../components/OrganicStore/ProductsList"
// import Sidebar from "../components/OrganicStore/SideBar"

const OrganicStorePage = ({ params, ...props }) => {
  const activeCategory = params["*"] || null

  return (
    <>
      <Seo title="Organic Store" />
      <Breadcrumbs title={"Organic Store"} />

      <section className={"py-5 my-4"}>
        <div className="container">
          <div className="row">
            {/*<div className="d-none d-md-block col-md-4 col-xl-3">*/}
            {/*  <Sidebar activeCategory={activeCategory} />*/}
            {/*</div>*/}
            <div className="col-md-12">
              <h4>Products</h4>
              <div className="row">
                <ProductsList category={activeCategory} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OrganicStorePage
