import React, { useContext, useState, useEffect } from "react"
import { StoreContext } from "../../../contexts/Store"
import Product from "../Product"

const ProductsList = ({ category = null }) => {
  const {
    store: { products },
  } = useContext(StoreContext)

  const [items, setItems] = useState(products)

  useEffect(() => {
    if (products.length === 0) return
    if (!category) {
      setItems(products)
    } else {
      setItems(
        products.filter(product => product.product_category.slug === category)
      )
    }
  }, [category, products])
  return (
    <>
      {items.map(product => {
        return <Product product={product} key={product.id} />
      })}
    </>
  )
}

export default ProductsList
